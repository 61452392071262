import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../assets/images/developers/meta.png'
import tag from '../assets/images/developers/tag.svg'
import Svyat from '../assets/images/story/wolt/svyat.png'
import CustomerLogos from '../components/CustomerLogos'
import Layout from '../components/Layout'
import CTABlock from '../components/blocks/CTABlock'
import ColumnsBlock, { gridSpacing } from '../components/blocks/ColumnsBlock'
import HeroBlock from '../components/blocks/HeroBlock'
import LeadBlock from '../components/blocks/LeadBlock'
import QuoteBlock from '../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../components/changelog/FeaturedBlogArticles'
import Block from '../components/primitives/Block'
import Box from '../components/primitives/Box'
import Row from '../components/primitives/Row'
import ShadowFrame from '../components/primitives/ShadowFrame'
import Stack from '../components/primitives/Stack'
import Blurb from '../components/system/Blurb'
import { responsiveScale } from '../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/developers/hero-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    lead: file(relativePath: { eq: "images/developers/pr-insights.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1322)
      }
    }
    lead2: file(
      relativePath: { eq: "images/product/work-log/anti-patterns.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1440)
      }
    }
    metrics: file(relativePath: { eq: "images/developers/metrics.png" }) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    blockers: file(
      relativePath: { eq: "images/product/overview/blockers.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    career: file(
      relativePath: { eq: "images/developers/career-discussions.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    agreements: file(
      relativePath: { eq: "images/developers/working-agreements.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    comments: file(
      relativePath: { eq: "images/developers/slack-comments.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    recaps: file(relativePath: { eq: "images/developers/daily-recaps.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
    completed: file(
      relativePath: { eq: "images/developers/issue-completed.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
  }
`

const DevelopersPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Swarmia for developers: A linter for teamwork"
      variant="dark"
      isNew
      description="Just like a linter helps improve your code, Swarmia helps improve the way your software development team works together."
      metaImage={MetaImage}
    >
      <HeroBlock
        tag={tag}
        title={<>A linter for teamwork</>}
        content="Just like a linter helps improve your code, Swarmia helps improve the way your team collaborates and communicates."
        backgroundImage={getImage(data.backgroundImage)!}
        primaryButton={{ text: 'Start free trial', href: '/signup/' }}
        secondaryButton={null}
      />
      <Box
        // This is needed for the styles to work
        className="main-body-old-styles"
      >
        <CustomerLogos />
      </Box>
      <LeadBlock
        heading={<>Get your pull requests through&nbsp;faster</>}
        content="With GitHub, you have to separately check each repository and find real PRs from a sea of bot-created dependency upgrade PRs. Swarmia brings all your team’s PRs into a single view, with bot PRs neatly pulled into their own page."
        alt="Pull requests view"
        image={getImage(data.lead)}
      />
      <QuoteBlock person="tiina" />
      <LeadBlock
        heading={<>Automate feedback loops</>}
        content="Speed up developer workflows with working agreements and Swarmia’s two-way integration between GitHub and Slack."
        link={{
          title: 'Learn more about feedback loops',
          href: '/product/working-agreements',
        }}
      />
      <Block
        paddingTop={responsiveScale(64)}
        paddingBottom={responsiveScale(92)}
      >
        <Row
          maxWidth="largeBlock"
          space={gridSpacing}
          justifyContent="center"
          css={{ margin: 'auto' }}
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          <Box flex={1}>
            <Stack key="1" space={gridSpacing}>
              <Blurb
                title="Read, reply, and react to GitHub comments in Slack"
                text="Instead of jumping between apps, use Swarmia’s Slack app to read, reply, and react to GitHub comments."
                image={getImage(data.comments)}
                isLarge
              />
              <AlexQuote />
            </Stack>
          </Box>
          <Box flex={1}>
            <Stack key="2" space={gridSpacing}>
              <Blurb
                title="Work better together"
                text="Adopt healthy working agreements as a team. You can also set numeric targets for cycle time, code reviews, work in progress, and more."
                image={getImage(data.agreements)}
                isLarge
              />
              <Row
                width="100%"
                space={gridSpacing}
                flexDirection={{
                  xs: 'column',
                  sm: 'row',
                  md: 'column',
                  lg: 'row',
                }}
              >
                <Box flex={1}>
                  <Blurb
                    title="Get daily recaps"
                    text="Turn on daily notifications on your team’s channel to see all the ongoing work at a glance."
                    image={getImage(data.recaps)}
                  />
                </Box>
                <Box flex={1}>
                  <Blurb
                    title={<>Celebrate completed work</>}
                    text="Swarmia summarizes completed projects in Slack to increase visibility and help you celebrate milestones."
                    image={getImage(data.completed)}
                  />
                </Box>
              </Row>
            </Stack>
          </Box>
        </Row>
        <QuoteBlock person="josep" />
        <LeadBlock
          heading="Start better conversations based&nbsp;on real data"
          content="Data doesn’t always give you the right answers. But it does help you bring up important topics, so you can start improving your processes and systems together."
          alt="Work Log"
          image={getImage(data.lead2)}
        />
        <ColumnsBlock
          paddingBottom={responsiveScale(64)}
          columns={[
            <Blurb
              key="1"
              title="Measure the right things"
              text="Get a baseline of key productivity metrics and identify concrete improvement areas."
              image={getImage(data.metrics)}
            />,
            <Blurb
              key="2"
              title="Spot and remove blockers"
              text="Get context by zooming into specific tasks, pull requests, and commits."
              image={getImage(data.blockers)}
            />,
            <Blurb
              key="3"
              title="Have more informed career discussions"
              text="Improve the quality of your 1:1s and career development discussions."
              image={getImage(data.career)}
            />,
          ]}
        />
      </Block>
      <Box paddingBottom={responsiveScale(64)}>
        <CTABlock
          title="Join the high-performing teams using Swarmia today."
          primaryButton={{ text: 'Start free trial', href: '/signup/' }}
          secondaryButton={null}
        />
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/building-software-the-swarmia-way/',
            '/blog/how-we-use-swarmia-at-swarmia/',
            '/blog/github-slack-integration/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

export default DevelopersPage

const AlexQuote = () => {
  return (
    <ShadowFrame
      paddingTop={40}
      paddingRight={40}
      paddingBottom={32}
      paddingLeft={40}
    >
      <Stack space={20}>
        <Box
          fontFamily="FactorA"
          fontSize={24}
          lineHeight="150%"
          fontWeight={700}
          maxWidth={500}
        >
          “Swarmia’s Slack notifications make it easy to keep track of open pull
          requests across all our repos. They help me quickly find the
          conversations that need my input.”
        </Box>
        <Row space={16} alignItems="center">
          <Box.img loading="lazy" src={Svyat} width={64} borderRadius={9999} />
          <Stack>
            <Box font="small" fontWeight={700}>
              Svyat Sobol
            </Box>
            <Box font="small">Lead Engineer at Wolt</Box>
          </Stack>
        </Row>
      </Stack>
    </ShadowFrame>
  )
}
